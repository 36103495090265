<script setup>
import { Link, usePage, useForm } from '@inertiajs/vue3';
// import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import { onMounted, onUnmounted, ref, watch, defineProps, onBeforeMount } from 'vue';
import { trans, getActiveLanguage } from 'laravel-vue-i18n';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import Modal from '@/Components/Modal.vue';
import Calendar from '@/Components/Calendar.vue';
import PlaySVG from '../../images/play.svg?component';
import StarEmptySVG from '../../images/star-empty.svg?component';
import StarHalfSVG from '../../images/star-half.svg?component';
import StarFullSVG from '../../images/star-full.svg?component';
import BillSVG from '../../images/bill.svg?component';
import ClockSVG from '../../images/clock.svg?component';
import dayjs from 'dayjs';
import axios from 'axios';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from "dayjs/plugin/localizedFormat";


dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

const currentLanguage = ref(getActiveLanguage());

const page = usePage()
const desktopVideo = ref();
const mobileVideo = ref();
const desktopVideoActive = ref(false);
const mobileVideoActive = ref(false);
const descriptionActive = ref(false);
const postRef = ref('');
const url = ref(page.url);
const params = new URLSearchParams(url.value.split('?')[1]);
const selectedTimezone = ref('UTC');
const selectedTimeSlot = ref(null);
const loading = ref(false);
const availableTimeSlots = ref([]);
const message = ref('');
const stage = ref('date');

const isModalOpen = ref(false);

const selectTimeSlot = (time) => {
  selectedTimeSlot.value = time;
  console.log('Selected Time Slot:', selectedTimeSlot.value);
};

const selectedDate = ref(dayjs());

const isScheduleModalOpen = ref(false);
const openScheduleModal = () => {
    if (typeof window !== 'undefined') {
        if (window._paq) {
            window._paq.push(['trackEvent', 'Posts Interactions', 'Welcome Page Clicks', 'Schedule a Lesson', undefined, {
                dimension1: `${props.post.id}` // Post ID
            }]);
        }
    }
  isScheduleModalOpen.value = true;
};

const closeScheduleModal = () => {
    isScheduleModalOpen.value = false;
    message.value = '';
    selectedTimeSlot.value = null;
    availableTimeSlots.value = [];
    stage.value = 'date';
    selectedDate.value = dayjs();
    loading.value = false;
};

const askQuestionReport = () => {
    if (typeof window !== 'undefined') {
        if (window._paq) {
            window._paq.push(['trackEvent', 'Posts Interactions', 'Welcome Page Clicks', 'Ask a Question', undefined, {
                dimension1: `${props.post.id}` // Post ID
            }]);
        }
    }
};

const bookALessonReport = () => {
    if (typeof window !== 'undefined') {
        if (window._paq) {
            window._paq.push(['trackEvent', 'Posts Interactions', 'Welcome Page Clicks', 'Book a Lesson', undefined, {
                dimension1: `${props.post.id}` // Post ID
            }]);
        }
    }
};

const playVideoReport = () => {
    if (typeof window !== 'undefined') {
        if (window._paq) {
            window._paq.push(['trackEvent', 'Posts Interactions', 'Welcome Page Clicks', 'Play Video', undefined, {
                dimension1: `${props.post.id}` // Post ID
            }]);
        }
    }
};

const playDesktopVideo = () => {
    desktopVideoActive.value = true;
    scrollToPost();
    playVideoReport();
    // video.value.play();
}

const playMobileVideo = () => {
    mobileVideoActive.value = true;
    scrollToPost();
    playVideoReport();
    // video.value.play();
}

const scrollToPost = () => {
  postRef.value.scrollIntoView({ behavior: 'smooth' });
}

const user = ref(page.props.auth.user);

const props = defineProps({
  post: {
    type: Object,
    required: true
  },
  selected: {
    type: Boolean,
    required: false
  }
});

watch(selectedDate, (newValue) => {
  if (props.post.user.ea_provider_id && props.post.ea_service_id) {
    console.log('Fetching availabilities...');
    availableTimeSlots.value = [];
    fetchAvailabilities(newValue);
  }
});

const createAbortController = () => {
  return new AbortController();
};

let currentRequestController = createAbortController();

const fetchAvailabilities = async (date) => {
  if (loading.value) {
    currentRequestController.abort();
  }
  message.value = '';
  selectedTimeSlot.value = null;
  loading.value = true;
  currentRequestController = createAbortController();
  const formattedDate = date.format('YYYY-MM-DD');

  try {
    const response = await axios.get(route('schedule.' + currentLanguage.value), {
        params: {
            post_id: props.post.id,
            ea_provider_id: props.post.user.ea_provider_id,
            ea_service_id: props.post.ea_service_id,
            date: formattedDate
        }
    }, {
      signal: currentRequestController.signal
    });

    availableTimeSlots.value = response.data.map((time) => {
      return dayjs.utc(`${formattedDate} ${time}`).local().format('HH:mm');
    }); 
    console.log('response.data', response.data);
    if (response.data.length == 0) {
      message.value = trans('schedule.no_availabilities');   
    } else {
      message.value = '';
    }

  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching availabilities:', error);
    }
  } finally {
    loading.value = false;
  }
};

watch(isScheduleModalOpen, (newValue) => {
  if (newValue && props.post.user.ea_provider_id && props.post.ea_service_id) {
    console.log('Modal opened. Fetching availabilities...');
    fetchAvailabilities(selectedDate.value);
  }
});

const schedule = () => {
    stage.value = 'confirm';
}

const back = () => {
    stage.value = 'date';
    selectedTimeSlot.value = null;
    availableTimeSlots.value = [];
    message.value = '';
    selectedDate.value = dayjs();
    fetchAvailabilities(selectedDate.value);
}

const form = useForm({
    date: null,
    time: null,
    post_id: props.post.id, // Assuming post_id comes from the props
});

const confirmed = ref(false);

const confirm = () => {
    let formattedDate = selectedDate.value.format('YYYY-MM-DD');
    confirmed.value = true;
    form.date = formattedDate;
    form.time = dayjs(`${formattedDate} ${selectedTimeSlot.value}`).utc().format('HH:mm');
    form.user_id = user.value.id;
    form.post(route(`schedule.${currentLanguage.value}`), {
        onFinish: () => form.reset(),
    });
};

if (props.selected) {
    onMounted(() => {
        scrollToPost();
        openScheduleModal();
    });
}

// Tracking views

let viewTimeout = null;
let hasTrackedView = false;
let hasTrackedReView = false;

const trackView = () => {
  if (!hasTrackedView) {
    window._paq.push(['trackEvent', 'Posts Interactions', 'Welcome Page Views', 'View', undefined, {
        dimension1: `${props.post.id}` // Post ID
    }]);
    hasTrackedView = true;
  }
};

// const trackReView = () => {
//     if (!hasTrackedReView) {
//         window._paq.push(['trackEvent', 'Posts Interactions', 'Welcome Page Views', 'ReView']);
//         hasTrackedReView = true;
//     }
// };

const handleIntersection = (entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
            if (!hasTrackedView) {
                viewTimeout = setTimeout(trackView, 1000);
            // } else if (!hasTrackedReView) {
            //     viewTimeout = setTimeout(trackReView, 1000);
            }
        } else {
            clearTimeout(viewTimeout);
            // if (entry.isIntersecting && (entry.intersectionRatio < 0 || entry.intersectionRatio > 1)) {
            //     hasTrackedReView = false;
            // }
        }
    });
};

if (typeof window !== 'undefined') {

    onMounted(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            // threshold: [0, 0.5, 1]
            threshold: [0.5]
        });

        if (postRef.value) {
            observer.observe(postRef.value);
        }

        onUnmounted(() => {
            if (postRef.value) {
            observer.unobserve(postRef.value);
            }
            clearTimeout(viewTimeout);
        });

    });

}

</script>

<template>
    <div class="hidden lg:block p-5 bg-white sm:rounded-lg p-2" ref="postRef" itemscope itemtype="https://schema.org/Course">
        <div class="hidden">
            <div itemprop="name" lang="currentLanguage">{{ post.title }}</div>
            <div itemprop="description" lang="currentLanguage">{{ post.description }}</div>
            <div itemprop="provider" itemscope itemtype="https://schema.org/Person">
                <div itemprop="name">{{ post.user.first_name }} {{ post.user.last_name }}</div>
                <img itemprop="image" :alt="`${post.user.first_name} ${post.user.last_name} Profile Picture`" :title="`${post.user.first_name} ${post.user.last_name} Profile Picture`">{{ post.user_image }}/>
            </div>
            <div v-if="post.rating_count > 0" itemprop="aggregateRating" itemscope itemtype="https://schema.org/AggregateRating">
                <div itemprop="ratingValue">{{ post.rating <= 0 ? 5 : 0 }}</div>
                <div itemprop="reviewCount">{{ post.rating_count }}</div>
            </div>
            <div itemprop="offers" itemscope itemtype="https://schema.org/Offer">
                <div itemprop="price">{{ post.price }}</div>
                <div itemprop="priceCurrency">{{ post.currency }}</div>
                <div itemprop="availability" content="https://schema.org/InStock"></div>
                <meta itemprop="category" content="Tutoring" />
            </div>
            <span itemprop="educationalAlignment" itemscope itemtype="https://schema.org/AlignmentObject">
                <meta itemprop="alignmentType" content="EducationalSubject">
                <span itemprop="targetName">{{ post.subject.name }}</span>
            </span>
            <div itemscope itemtype="https://schema.org/CourseInstance" itemprop="hasCourseInstance">
                <meta v-if="post.online && !post.in_person" itemprop="courseMode" content="Online" />
                <meta v-if="post.in_person && !post.online" itemprop="courseMode" content="Onsite" />
                <meta v-if="post.online && post.in_person" itemprop="courseMode" content="Blended" />
                <div itemscope itemtype="https://schema.org/Person" itemprop="instructor">
                    <meta itemprop="name" :content="`${post.user.first_name} ${post.user.last_name}`" />
                </div>
                <meta itemprop="courseWorkload" content="P2D" />
            </div>
            <time itemprop="duration" :datetime="`PT${post.duration}M`">{{ post.duration }} Minutes }}</time>
            <span v-if="post.in_person" itemprop="location" itemscope itemtype="https://schema.org/Place">
                <span itemprop="name">{{ post.city }}, {{ post.country }}</span>
            </span>
            <span itemprop="inLanguage" itemscope itemtype="https://schema.org/Language">
                <span v-if="post.languages[0]" itemprop="name">{{ post.languages[0].name }}</span>
            </span>
            <div v-if="post.languages.length > 1" v-for="language in post.languages.slice(1)" itemprop="availableLanguage" itemscope itemtype="https://schema.org/Language">
                <span itemprop="name">{{ language.name }}</span>
            </div>
            <a v-if="post.slug" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })" itemprop="url">{{ route(`post.show.${currentLanguage}`, { post: post.post_slug }) }}</a>
        </div>
        <div class="w-full grid min-h-52 gap-5 grid-cols-[11rem_auto_18rem]">
            <div>
                <Link v-if="post.slug" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })">
                    <img :src="post.user_image" :alt="`${post.user.first_name} ${post.user.last_name} Profile Picture`" :title="`${post.user.first_name} ${post.user.last_name} Profile Picture`" class="w-40 h-40 object-cover rounded-lg" loading="lazy">
                </Link>
                <img v-else :src="post.user_image" :alt="`${post.user.first_name} ${post.user.last_name} Profile Picture`" :title="`${post.user.first_name} ${post.user.last_name} Profile Picture`" class="w-40 h-40 object-cover rounded-lg" loading="lazy">
                <Link v-if="post.video_url && post.slug" class="mt-2 p-2 flex gap-2 items-center bg-gray-100 cursor-pointer" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })">
                    <div class="svg-image"><PlaySVG /></div>
                    <div class="text-secondary font-semibold">{{ $t('Watch video') }}</div>
                </Link>
                <div v-else-if="post.video_url" class="mt-2 p-2 flex gap-2 items-center bg-gray-100 cursor-pointer" @click="playDesktopVideo">
                    <div class="svg-image"><PlaySVG /></div>
                    <div class="text-secondary font-semibold">{{ $t('Watch video') }}</div>
                </div>
            </div>
            <div class="flex flex-col justify-between">
                <div class="flex flex-col gap-2">
                    <div class="flex justify-between">
                        <div class="flex gap-1 items-center">
                            <Link v-if="post.slug" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })">
                                <div :id="post.id" class="text-2xl font-bold">
                                    {{ post.user.first_name }} {{ post.user.last_name }}
                                </div>
                            </Link>
                            <div v-else :id="post.id" class="text-2xl font-bold">
                                {{ post.user.first_name }} {{ post.user.last_name }}
                            </div>
                            <template v-if="post.user.active_status">
                                <div class="text-6xl leading-6 text-green-600">&middot;</div>
                                <div class="text-sm font-semibold text-green-600">ONLINE</div>
                            </template>
                            <template v-else>
                                <div class="text-6xl leading-6 text-gray-400">&middot;</div>
                                <div class="text-sm font-semibold text-gray-400 uppercase">{{ post.last_online }}</div>
                            </template>
                        </div>
                        <div class="flex gap-2 items-center">
                        </div>
                        <div class="flex gap-2 items-center text-base">
                            <div class="flex gap-1 items-center" v-if="post.rating > 0">
                                <template v-for="rating in [1, 2, 3, 4, 5]">
                                    <StarFullSVG v-if="post.rating > rating - 0.5" />
                                    <StarHalfSVG v-else-if="post.rating > rating - 1" />
                                    <StarEmptySVG v-else />
                                </template>
                                <div class="text-gray-500">({{ post.rating_count }})</div>
                            </div>
                        </div>
                    </div>
                    <Link v-if="post.post_slug" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })">
                        <div class="text-gray-500 font-semibold">{{ post.title }}</div>
                    </Link>
                    <div v-else class="text-gray-500 font-semibold">{{ post.title }}</div>
                    <div class="relative">
                        <div v-if="!descriptionActive" class="relative text-gray-500 overflow-hidden">
                            <div class="overflow-hidden max-h-[106px]" v-html="post.description.replace(/\n/g, '<br />')"></div>
                            <div class="absolute top-[76px] left-0 text-gray-400 cursor-pointer bg-white py-1 w-full" @click="descriptionActive = true">{{ $t('See more') }}...</div>
                        </div>
                        <div v-if="descriptionActive" class="text-gray-500">
                            <div v-html="post.description.replace(/\n/g, '<br />')"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <template v-if="post.in_person">
                        <span class="p-2 inline-block text-gray-500 bg-gray-100 mr-4">{{ $t('In-person location') }}: {{ post.city }}, {{ $t(post.country) }}</span>
                    </template>
                    <template v-if="post.online">
                        <span class="p-2 inline-block text-gray-500 bg-gray-100 mr-4">{{ $t('Online class') }}</span>
                    </template>
                    <template v-if="post.languages.length > 0">
                        <span class="p-2 inline-block text-gray-500 bg-gray-100 my-2">{{ $t('Teaches in') }}: 
                            {{ post.languages.map((language) => ['en', 'ru'].includes(currentLanguage) ? $t(`languages.${language.name}`) : $t(`languages.${language.name}`).toLowerCase()).join(', ') }}
                        </span>
                    </template>
                </div>
            </div>
            <div class="flex flex-col justify-between">
                <div class="w-full grid grid-cols-2 gap-4">
                    <div class="bg-gray-200 p-4 rounded-md">
                        <div class="svg-image h-8"><BillSVG /></div>
                        <div class="font-semibold">{{ post.price }} {{ post.currency}}</div>
                    </div>
                    <div class="bg-gray-200 p-4 rounded-md">
                        <div class="svg-image h-8"><ClockSVG /></div>
                        <div class="font-semibold">{{ post.duration }} {{ $t('min') }}</div>
                    </div>
                </div>
                <div class="flex flex-col justify-end gap-2" v-if="!user || user.id !== post.user.id">
                    <template v-if="user">
                        <a :href="route(`user.${currentLanguage}`, { id: post.user.id })" @click="askQuestionReport">
                            <SecondaryButton class="w-full text-primary font-semibold border-primary justify-center">
                                {{ $t('Ask a question') }}
                            </SecondaryButton>
                        </a>
                        <a v-if="!post.user.schedule_enabled || post.ea_service_id === null" :href="route(`user.${currentLanguage}`, { id: post.user.id })"  @click="bookALessonReport">
                            <PrimaryButton class="w-full">
                                {{ $t('Book a lesson') }}
                            </PrimaryButton>
                        </a>
                        <PrimaryButton v-else class="w-full" @click="openScheduleModal">
                            {{ $t('Book a lesson') }}
                        </PrimaryButton>
                    </template>
                    <template v-else >
                        <Link :href="route(`login.${currentLanguage}`, { redirect: route(`user.${currentLanguage}`, { id: post.user.id }) })" @click="askQuestionReport">
                            <SecondaryButton class="w-full text-primary font-semibold border-primary justify-center">
                                {{ $t('Ask a question') }}
                            </SecondaryButton>
                        </Link>
                        <Link v-if="!post.user.schedule_enabled || post.ea_service_id === null" :href="route(`login.${currentLanguage}`, { redirect: route(`user.${currentLanguage}`, { id: post.user.id }) })"  @click="bookALessonReport">
                            <PrimaryButton class="w-full">
                                {{ $t('Book a lesson') }}
                            </PrimaryButton>
                        </Link>
                        <Link v-else :href="route(`login.${currentLanguage}`, { redirect: route(`posts.${currentLanguage}`, { id: post.id }) })"  @click="bookALessonReport">
                            <PrimaryButton class="w-full">
                                {{ $t('Book a lesson') }}
                            </PrimaryButton>
                        </Link>
                    </template>
                </div>
            </div>
        </div>
        <div :style="{ 'aspect-ratio': !desktopVideoActive ? '200' : '16/4.8', 'transition': 'aspect-ratio .6s' }" class="overflow-hidden">
            <div v-if="desktopVideoActive && post.video_url" class="pt-4 w-[50%] h-full">
                <video class="max-h-full max-w-full" ref="desktopVideo" :src="post.video_url" controls autoplay controlslist="nodownload nofullscreen noremoteplayback noplaybackrate" disablePictureInPicture></video>
            </div>
        </div>
    </div>
    <div class="block lg:hidden p-5 bg-white rounded-none p-2">
        <div class="flex gap-4 w-full">
            <div>
                <Link v-if="post.post_slug" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })">
                    <img :src="post.user_image" :alt="`${post.user.first_name} ${post.user.last_name} Profile Picture`" :title="`${post.user.first_name} ${post.user.last_name} Profile Picture`" class="w-28 h-28 object-cover rounded-lg"loading="lazy">
                </Link>
                <img v-else :src="post.user_image" :alt="`${post.user.first_name} ${post.user.last_name} Profile Picture`" :title="`${post.user.first_name} ${post.user.last_name} Profile Picture`" class="w-28 h-28 object-cover rounded-lg"loading="lazy">
            </div>
            <div class="w-auto">
                <div>
                    <Link v-if="post.post_slug" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })">
                        <div class="text-2xl font-bold">{{post.user.first_name}} {{post.user.last_name}}</div>
                    </Link>
                    <div v-else class="text-2xl font-bold">{{post.user.first_name}} {{post.user.last_name}}</div>
                    <div class="flex gap-2 items-center">
                        <template v-if="post.user.active_status">
                            <div class="text-6xl leading-6 text-green-600">&middot;</div>
                            <div class="text-sm font-semibold text-green-600">ONLINE</div>
                        </template>
                        <template v-else>
                            <div class="text-6xl leading-6 text-gray-400">&middot;</div>
                            <div class="text-sm font-semibold text-gray-400 uppercase">{{ post.last_online }}</div>
                        </template>
                    </div>
                </div>
                <div class="flex gap-2 items-center text-base">
                    <div class="flex gap-1 items-center" v-if="post.rating > 0">
                        <template v-for="rating in [1, 2, 3, 4, 5]">
                            <StarFullSVG v-if="post.rating > rating - 0.5" />
                            <StarHalfSVG v-else-if="post.rating > rating - 1" />
                            <StarEmptySVG v-else />
                        </template>
                        <div class="text-gray-500">({{ post.rating_count }})</div>
                    </div>
                </div>
                <Link v-if="post.video_url && post.post_slug" class="mt-2 p-2 flex gap-2 items-center bg-gray-100 cursor-pointer" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })">
                    <div class="svg-image"><PlaySVG /></div>
                    <div class="text-secondary font-semibold">{{ $t('Watch video') }}</div>
                </Link>
                <div v-else-if="post.video_url" class="mt-2 p-2 flex gap-2 items-center bg-gray-100 cursor-pointer" @click="playMobileVideo">
                    <div class="svg-image"><PlaySVG /></div>
                    <div class="text-secondary font-semibold">{{ $t('Watch video') }}</div>
                </div>
            </div>
        </div>
        <div class="my-4">
            <Link v-if="post.post_slug" :href="route(`post.show.${currentLanguage}`, { post: post.post_slug })">
                <div class="text-gray-500 font-semibold">{{ post.title }}</div>
            </Link>
            <div v-else class="text-gray-500 font-semibold">{{ post.title }}</div>
        </div>
        <div class="my-4 relative">
            <div v-if="!descriptionActive" class="relative text-gray-500 overflow-hidden">
                <div class="overflow-hidden max-h-[106px]" v-html="post.description.replace(/\n/g, '<br />')"></div>
                <div class="absolute top-[76px] left-0 text-gray-400 cursor-pointer bg-white py-1 w-full" @click="descriptionActive = true">{{ $t('See more') }}...</div>
            </div>
            <div v-if="descriptionActive" class="text-gray-500 overflow-hidden">
                <div v-html="post.description.replace(/\n/g, '<br />')"></div>
            </div>
        </div>
        <div class="flex flex-wrap gap-2">
            <template v-if="post.in_person">
                <span class="p-2 text-gray-500 bg-gray-100">{{ $t('In-person location') }}: {{ post.city }}, {{ $t(post.country) }}</span>
            </template>
            <template v-if="post.online">
                <span class="p-2 text-gray-500 bg-gray-100">{{ $t('Online class') }}</span>
            </template>
            <template v-if="post.languages.length > 0">
                <span class="p-2 text-gray-500 bg-gray-100">{{ $t('Teaches in') }}: 
                    {{ post.languages.map((language) => ['en', 'ru'].includes(currentLanguage) ? $t(`languages.${language.name}`) : $t(`languages.${language.name}`).toLowerCase()).join(', ') }}
                </span>
            </template>
        </div>
        <div class="flex gap-4 my-4">
            <div class="w-1/2 inline-flex items-center justify-center bg-gray-200 p-4 rounded-md">
                <div class="svg-image mr-2"><BillSVG /></div>
                <div class="font-semibold ">{{ post.price }} {{ post.currency}}</div>
            </div>
            <div class="w-1/2 inline-flex items-center justify-center bg-gray-200 p-4 rounded-md">
                <div class="svg-image mr-2"><ClockSVG /></div>
                <div class="font-semibold">{{ post.duration }} {{ $t('min') }}</div>
            </div>
        </div>
        <div class="my-4" v-if="!user || user.id !== post.user.id">
            <template v-if="user">
                <a :href="route(`user.${currentLanguage}`, { id: post.user.id })" @click="askQuestionReport">
                    <SecondaryButton class="w-full text-primary font-semibold border-primary justify-center my-2">
                        {{ $t('Ask a question') }}
                    </SecondaryButton>
                </a>
                <a v-if="!post.user.schedule_enabled || post.ea_service_id === null" :href="route(`user.${currentLanguage}`, { id: post.user.id })" @click="bookALessonReport">
                    <PrimaryButton class="w-full">
                        {{ $t('Book a lesson') }}
                    </PrimaryButton>
                </a>
                <PrimaryButton v-else class="w-full" @click="openScheduleModal">
                    {{ $t('Book a lesson') }}
                </PrimaryButton>
            </template>
            <template v-else >
                <Link :href="route(`login.${currentLanguage}`, { redirect: route(`user.${currentLanguage}`, { id: post.user.id }) })" @click="askQuestionReport">
                    <SecondaryButton class="w-full text-primary font-semibold border-primary justify-center my-2">
                        {{ $t('Ask a question') }}
                    </SecondaryButton>
                </Link>
                <Link v-if="!post.user.schedule_enabled || post.ea_service_id === null" :href="route(`login.${currentLanguage}`, { redirect: route(`user.${currentLanguage}`, { id: post.user.id }) })" @click="bookALessonReport">
                    <PrimaryButton class="w-full">
                        {{ $t('Book a lesson') }}
                    </PrimaryButton>
                </Link>
                <Link v-else :href="route(`login.${currentLanguage}`, { redirect: route(`posts.${currentLanguage}`, { id: post.id }) })"  @click="bookALessonReport">
                    <PrimaryButton class="w-full">
                        {{ $t('Book a lesson') }}
                    </PrimaryButton>
                </Link>
            </template>
        </div>
        <div :style="{ 'aspect-ratio': !mobileVideoActive ? '200' : '16/4.8', 'transition': 'aspect-ratio .6s' }" class="overflow-hidden">
            <div v-if="mobileVideoActive && post.video_url" class="pt-4 w-[50%] h-full">
                <video class="max-h-full max-w-full" ref="mobileVideo" :src="post.video_url" controls autoplay controlslist="nodownload nofullscreen noremoteplayback noplaybackrate" disablePictureInPicture></video>
            </div>
        </div>
    </div>
    <Modal :show="isScheduleModalOpen" @close="closeScheduleModal">
        <div v-if="stage === 'date'" class="p-2 md:p-6">
            <div>
                <div class="text-2xl font-semibold">{{ $t('Schedule a lesson') }}</div>
            </div>
            <div class="mt-3 mb-6 flex flex-col sm:flex-row gap-6">
                <div>
                    <Calendar v-model="selectedDate" :locale="currentLanguage" />
                </div>
                <div class="sm:w-[200px] sm:mt-9">
                    <div class="available-time-slots-container">
                        <div v-for="time in availableTimeSlots" :key="time">
                            <div 
                                class="w-full text-center font-bold border py-2.5 px-3 my-2 cursor-pointer rounded"
                                :class="{
                                    'text-primary border-primary hover:bg-primary-light hover:text-white': time !== selectedTimeSlot,
                                    'bg-primary text-white border-primary': time === selectedTimeSlot
                                }"
                                @click="selectTimeSlot(time)"
                            >
                                {{ time }}
                            </div>
                        </div>
                        <div v-if="message" class="text-gray-500 mt-2">{{ message }}</div>
                    </div>
                </div>
            </div>
            <div>
                <div class="flex gap-4 justify-end">
                    <SecondaryButton @click="closeScheduleModal">{{ $t('Cancel') }}</SecondaryButton>
                    <PrimaryButton @click="schedule" :disabled="!selectedTimeSlot">{{ $t('Schedule') }}</PrimaryButton>
                </div>
            </div>
        </div>
        <div v-if="stage === 'confirm'" class="p-2 md:p-6">
            <div>
                <div class="text-2xl font-semibold">{{ $t('Schedule Confirmation') }}</div>
            </div>
            <div class="mt-3 mb-3 flex flex-col gap-6">
                <div class="text-gray-500 p-2 pt-4">{{ $t('schedule.confirmation_message') }}</div>
                <div class="p-2 pb-4">
                    <div class="text-gray-500">{{ $t('Date') }}: {{ selectedDate.format('YYYY-MM-DD') }}</div>
                    <div class="text-gray-500">{{ $t('Time') }}: {{ selectedTimeSlot }}</div>
                    <div class="text-gray-500">{{ $t('Duration') }}: {{ post.duration }} {{ $t('minutes') }}</div>
                    <div class="text-gray-500">{{ $t('Price') }}: {{ post.price }} {{ post.currency }}</div>
                </div>
                
            </div>
            <div>
                <div class="flex gap-4 justify-end">
                    <SecondaryButton @click="back" :disabled="confirmed">{{ $t('Back') }}</SecondaryButton>
                    <PrimaryButton @click="confirm" :disabled="!selectedTimeSlot || confirmed">{{ $t('Confirm') }}</PrimaryButton>
                </div>
            </div>
        </div>
    </Modal>
</template>

<style scoped>
.available-time-slots-container {
  max-height: 277px; /* Set the maximum height for the container */
  min-height: 92px; /* Set the minimum height for the container */
  overflow-y: auto;  /* Enable vertical scrolling if content exceeds the height */
  padding-right: 10px; /* Optional: adds padding to avoid scrollbar overlay */
  margin-top: 10px;
}

@media (max-width: 640px) {
    .available-time-slots-container {
        display: flex;
        gap: 10px;
        margin-top: 0px;
    }
}
</style>
