<script setup>
import { onMounted, computed, ref } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en';
import 'dayjs/locale/sr';
import 'dayjs/locale/ru';
import chevron_backward from '../../images/chevron.backward.svg?component';
import chevron_forward from '../../images/chevron.forward.svg?component';

dayjs.extend(isSameOrBefore);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

const props = defineProps({
  locale: {
    type: String
  },
  open: {
    type: Boolean
  }
});

const emit = defineEmits(['update:modelValue']);

const selectedDate = ref(dayjs().format('YYYY-MM-DD'));

function selectDate(date) {
  //console.log(selectedDate.value);
  selectedDate.value = date.date;
  // selectedDate.value = dayjs(displayedDate.value).date(parseInt(date.date, 10));
  // console.log('date', date);
  // console.log(parseInt(date.date, 10));
  // console.log(dayjs(date.date).date());
  // console.log(dayjs(displayedDate.value).date(parseInt(date.date, 10)));
  emit('update:modelValue', dayjs(selectedDate.value));
  if (date.isNextMonth) {
    nextMonth();
  } else if (date.isPreviousMonth) {
    previousMonth();
  }
}

// Displayed month and year
const displayedDate = ref(dayjs());

function previousMonth() {
  displayedDate.value = displayedDate.value.subtract(1, 'month');
}

function nextMonth() {
  displayedDate.value = displayedDate.value.add(1, 'month');
}

const dates = computed(() => getDatesForDisplayedMonth());

function getDatesForSelectedMonth() {
  const startDate = dayjs(selectedDate.value).startOf('month');
  const endDate = dayjs(selectedDate.value).endOf('month');
  const dates = [];
  let currentDate = startDate;
  for (let i = currentDate.day(); i > 1; i--) {
    dates.push({
      day: dayjs(currentDate).subtract(i, 'day').format('D'),
      date: dayjs(currentDate).subtract(i, 'day').format('YYYY-MM-DD'),
      isToday: isToday(dayjs(currentDate).subtract(i, 'day')),
      isPreviousMonth: true,
      isNextMonth: false,
      isDisabled: dayjs(currentDate).isBefore(dayjs(), 'day')
    });
  }
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push({
      day: currentDate.format('D'),
      date: currentDate.format('YYYY-MM-DD'),
      isToday: isToday(currentDate),
      isPreviousMonth: false,
      isNextMonth: false,
      isDisabled: dayjs(currentDate).isBefore(dayjs(), 'day')
    });
    currentDate = currentDate.add(1, 'day');
  }
  for (let i = currentDate.day(); i < 8; i++) {
    dates.push({
      day: currentDate.format('D'),
      date: currentDate.format('YYYY-MM-DD'),
      isToday: isToday(currentDate),
      isPreviousMonth: false,
      isNextMonth: true,
      isDisabled: dayjs(currentDate).isBefore(dayjs(), 'day')
    });
    currentDate = currentDate.add(1, 'day');
  }
  return dates;
}

function isToday(date) {
  return dayjs().isSame(date, 'day');
}

function nextYear() {
  displayedDate.value = displayedDate.value.add(1, 'year');
}

function previousYear() {
  displayedDate.value = displayedDate.value.subtract(1, 'year');
}

function getDatesForDisplayedMonth() {
  const startDate = displayedDate.value.startOf('month');
  const endDate = displayedDate.value.endOf('month');
  const dates = [];
  let currentDate = startDate;
  for (let i = currentDate.day(); i > 1; i--) {
    dates.push({
      // date: dayjs(currentDate).subtract(i, 'day').format('D'),
      day: dayjs(currentDate).subtract(i, 'day').format('D'),
      date: dayjs(currentDate).subtract(i, 'day').format('YYYY-MM-DD'),
      isToday: isToday(dayjs(currentDate).subtract(i, 'day')),
      isPreviousMonth: true,
      isNextMonth: false,
      isDisabled: true
    });
  }
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push({
      // date: currentDate.format('D'),
      day: currentDate.format('D'),
      date: currentDate.format('YYYY-MM-DD'),
      isToday: isToday(currentDate),
      isPreviousMonth: false,
      isNextMonth: false,
      isDisabled: dayjs(currentDate).isBefore(dayjs(), 'day')
    });
    currentDate = currentDate.add(1, 'day');
  }
  for (let i = currentDate.day(); i < 8; i++) {
    dates.push({
      // date: currentDate.format('D'),
      day: currentDate.format('D'),
      date: currentDate.format('YYYY-MM-DD'),
      isToday: isToday(currentDate),
      isPreviousMonth: false,
      isNextMonth: true,
      isDisabled: false
    });
    currentDate = currentDate.add(1, 'day');
  }
  return dates;
}

const hours = Array.from({length: 24}, (_, i) => i);

const minutes = Array.from({length: 60}, (_, i) => i);

const pause = 15;
const duration = 30;
const start = dayjs().hour(13).minute(0).second(0);
const slots = Array.from({length: 12}, (_, i) => start.add(i * (pause + duration), 'minute').format('HH:mm'));

</script>

<template>
  <div class="text-gray-500 font-light">
    <div class="flex justify-between p-2 max-w-lg text-center font-bold text-primary text-xl items-center">
      <div class="flex gap-8 items-center">        
        <div 
          class="w-2 cursor-pointer"
          :class="{
            'opacity-30': displayedDate.isSameOrBefore(dayjs(), 'month'),
            'pointer-events-none': displayedDate.isSameOrBefore(dayjs(), 'month') 
          }"
        ><chevron_backward @click="previousMonth" /></div>
        <span class="text-nowrap">{{dayjs(displayedDate).format('MMM YYYY')}}</span>
        <div class="w-2 cursor-pointer"><chevron_forward @click="nextMonth"/></div>
      </div>
      <!-- <div class="flex gap-8">
        <div class="cursor-pointer" @click="previousMonth"><chevron_backward /></div>
        <div class="cursor-pointer" @click="nextMonth"><chevron_forward /></div>
      </div> -->
    </div>
    <div>
      <div class="flex">
        <div class="grid grid-cols-7 gap-y-4 gap-x-4 md:gap-x-6 p-0 md:p-2 pt-4 max-w-lg">
          <div class="pr-0.5 md:pr-0 p-0 md:p-1 text-center text-sm md:text-md" v-for="day in ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']" :key="day">{{ day }}</div>
          <div v-for="date in dates">
            <div 
              class="text-center flex justify-center items-center cursor-pointer relative"
              :class="{
                'pointer-events-none': date.isDisabled
              }"
              @click="selectDate(date)"
            >
              <div 
                class="pointer-events-none absolute w-10 h-10 rounded-full"
                :class="{
                  'bg-primary': selectedDate === date.date,
                }"
                @click="selectDate(date)"
              ></div>
              <span
                class="pointer-events-none z-10"
                :class="{ 
                  'text-slate-400': date.isPreviousMonth || date.isNextMonth,
                  '!text-slate-300': date.isDisabled,
                  'text-white': selectedDate === date.date,
                }"
              >{{ date.day }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>