<script setup>
import { computed, watch } from 'vue';
import { Head, Link, usePage, router } from '@inertiajs/vue3';
import backwardSVG from '../../images/backward.svg?component';
import forwardSVG from '../../images/forward.svg?component';

// const emit = defineEmits(['update:checked']);

const props = defineProps({
    posts: {
        type: Object,
        required: true,
    },
});

// const visit = (url) => {
//   this.$emit('change', newValue);
//   router.visit(url, {
//     // preserveState: true,
//     preserveScroll: true,
//     replace: false,
//     method: 'get'
//     // replace: true
//   });
// }

</script>

<template>
    <div class="flex xl:w-[1216px] m-auto justify-center">
        <template v-if="props.posts">
            <div class="bg-white flex gap-6 px-5 py-3 rounded-md align-middle">
                <template v-for="link in props.posts.links" :key="link">
                    <Link v-if="link.url && link.label.includes('&l')" class="w-7 h-7 flex justify-center leading-7 rounded-full text-gray-500 text-nowrap flex flex-col justify-center items-center" :href="link.url" @click.prevent="$emit('change', props.posts.current_page - 1)"><backwardSVG /></Link>
                    <Link v-if="link.url && !link.label.includes('&')" :class="{ 'bg-primary': link.active, 'text-white': link.active }" class="w-7 h-7 flex justify-center leading-7 rounded-full text-gray-500 text-nowrap" :href="link.url" @click.prevent="$emit('change', Number(link.label))">{{ link.label }}</Link>
                    <Link v-if="link.url && link.label.includes('&r')" class="w-7 h-7 flex justify-center leading-7 rounded-full text-gray-500 text-nowrap flex flex-col justify-center items-center" :href="link.url" @click.prevent="$emit('change', props.posts.current_page + 1)"><forwardSVG /></Link>
                </template>
            </div>
        </template>
    </div>
</template>
