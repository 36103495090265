<script setup>
import { Head, Link, usePage, router } from '@inertiajs/vue3';
import { onMounted, ref } from 'vue'
import { getActiveLanguage, trans } from 'laravel-vue-i18n';
// import Dropdown from '@/Components/Dropdown.vue';
// import HeaderLayout from '@/Layouts/HeaderLayout.vue';
import Posts from '@/Components/Posts.vue';
import PageLayout from '@/Layouts/PageLayout.vue';
import Onboarding from '@/Components/Onboarding.vue';
// import NavLink from '@/Components/NavLink.vue';
// import PrimaryButton from '@/Components/PrimaryButton.vue';
import ArrowRightSVG from '../../images/arrow-right.svg?component';
import SearchSVG from '../../images/search-icon.svg?component';
import Pagination from '@/Components/Pagination.vue';
import StrokeSVG from '../../images/stroke.svg?component';
// import HeroSVG from '../../images/hero-image.svg?component';
import { URLEncoder } from '../utils/URLEncoder'

const page = usePage();
const locales = ref(page.props.available_locales);
const currentLanguage = ref(getActiveLanguage());
const url = ref(page.url);
const host = ref(page.props.host);
const posts = ref(page.props.posts); // Initialize posts as a ref
// const hasMore = ref(!!page.props.posts.next_page_url); // Initialize hasMore as a ref
const subjects = ref(page.props.subjects);
subjects.value.sort((a, b) => {
    if (a.language_id && !b.language_id) return -1;
    if (!a.language_id && b.language_id) return 1;  
    return a.name.localeCompare(b.name);
});
 
const tags = ref(page.props.tags);
const languages = ref(page.props.languages);
const selectedSubject = ref(page.props.subject ? trans(`subjects.${page.props.subject}`) : '');
const selectedTags = ref(page.props.tag ? trans(`subjects.${page.props.tag}`) : '');
const selectedLanguage = ref(page.props.language ? page.props.language : '');
const selectedPriceSort = ref(page.props.sort ? page.props.sort : '');
const searchCity = ref(page.props.city ? page.props.city : '');
const title = ref(page.props.title);
const description = ref(page.props.description);
const keywords = ref(page.props.keywords ? page.props.keywords : '');
const popularSubjects = ref(page.props.popularSubjects || ['Mathematics', 'English', 'Serbian', 'Python']);
const popularTags = ref(page.props.popularTags || ['College', 'High school', 'Primary school', 'Middle school']);
const selectedPost = ref(page.props.selectedPost);
const postsRef = ref(null);

const currentPage = ref(page.props.posts.current_page);
// const user = ref(page.props.auth.user);
// const newUser = ref(page.props.newUser);

// const sleep = seconds => new Promise(resolve => setTimeout(resolve, seconds * 1000));
// onMounted(async () => {
//   while (!isLoaded(getActiveLanguage())) {
//     await sleep(0.1);
//   }
//   selectedSubject.value = page.props.subject ? trans(page.props.subject) : '';
//   selectedTags.value = page.props.tag ? trans(page.props.tag) : '';
// });

// onBeforeMount(async () => {
//   // await loadLanguageAsync(currentLanguage.value); // this will cause infinite loop
//   selectedSubject.value = page.props.subject ? trans(page.props.subject) : '';
//   selectedTags.value = page.props.tag ? trans(page.props.tag) : '';
// });

const exploreReport = () => {
    if (typeof window !== 'undefined') {
        if (window._paq) {
            window._paq.push(['trackEvent', 'Page Interactions', 'Welcome Page Clicks', 'Explore']);
        }
    }
};

const searchReport = () => {
    if (typeof window !== 'undefined') {
        if (window._paq) {
            window._paq.push(['trackEvent', 'Page Interactions', 'Welcome Page Clicks', 'Search']);
        }
    }
};

// const debounce = (func, delay) => {
//   let timeoutId;
//   return (...args) => {
//     clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       func.apply(null, args);
//     }, delay);
//   };
// };

const scrollToSearch = () => {
  const element = document.getElementById('search');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
  exploreReport();
}

const scrollToResults = () => {
  const element = document.getElementById('results');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
  exploreReport();
}

const setSelectedTag = (tagName) => {
  selectedTags.value = tagName;
  // scrollToSearch();
  searchPosts(1);
}

const setSelectedSubject = (subjectName) => {
  selectedSubject.value = subjectName.charAt(0).toUpperCase() + subjectName.slice(1);
  selectedTags.value = '';
  // scrollToSearch();
  searchPosts(1);
}

const removeSearchCity = () => {
  searchCity.value = '';
  searchPosts(1);
}

const onSelectSubjectChange = (page) => {
  selectedTags.value = '';
  searchPosts(page);
}

const searchPosts = (page) => {
  currentPage.value = page;
  let url = `${route(`posts.${currentLanguage.value}`)}`;
  if (selectedSubject.value) {
    url += `/${URLEncoder.encode(`${selectedSubject.value.toLowerCase().replace(/\s/g, '-').replace(/\#/g, '-hash-').replace(/\//g, '-slash-').replace(/-$/, '')}`)}`;
    if (selectedTags.value) {
      url += `/${URLEncoder.encode(`${selectedTags.value.toLowerCase().replace(/\s/g, '-').replace(/\#/g, '-hash-').replace(/\//g, '-slash-').replace(/-$/, '')}`)}`;
    }
  } else if (selectedTags.value) {
    url += `/${URLEncoder.encode(`${selectedTags.value.toLowerCase().replace(/\s/g, '-').replace(/\#/g, '-hash-').replace(/\//g, '-slash-').replace(/-$/, '')}`)}`;
  }
  if (selectedLanguage.value) {
    url = url.includes('?') ? `${url}&` : `${url}?`;
    url += `speaking=${selectedLanguage.value}`;
  } else {
    url = url.includes('?') ? `${url}&` : `${url}?`;
    url += `speaking=any`;
  }
  if (selectedPriceSort.value) {
    url = url.includes('?') ? `${url}&` : `${url}?`;
    url += `sort=${selectedPriceSort.value}`;
  }
  if (searchCity.value) {
    url = url.includes('?') ? `${url}&` : `${url}?`;
    url += `city=${searchCity.value}`;
  }
  if (currentPage.value > 1) {
    url = url.includes('?') ? `${url}&` : `${url}?`;
    url += `page=${currentPage.value}`;
  }
  searchReport();
  router.visit(url, {
    // preserveState: true,
    preserveScroll: true,
    replace: false,
    method: 'get'
    // replace: true
  });
}

onMounted(() => {
  if (typeof window !== 'undefined') {
    if (selectedSubject.value || selectedTags.value || searchCity.value) {
      // setTimeout(scrollToSearch, 300);
      setTimeout(scrollToResults, 300);
    }
  }
});

defineProps({

});

</script>

<template>
  <PageLayout :url="url" :popularTags="popularTags" :setSelectedTag="setSelectedTag">
    <Head>
      <title>{{ title }}</title>
      <meta name="title" :content="title">
      <link rel="icon" type="image/x-icon" :href="`${host}/assets/icons/favicon.ico`">
      <link rel="manifest" :href="`${host}/assets/icons/manifest.json`">
      <link rel="canonical" :href="`${host}${url}`">
      <meta name="description" :content="description">
      <meta name="keywords" :content="keywords">
      <meta property="og:type" content="website">
      <meta property="og:url" :content="`${host}${url}`">
      <meta property="og:title" :content="title">
      <meta property="og:description" :content="description">
      <meta property="og:image" :content="`${host}/assets/page-preview.webp`">
      <meta property="og:image:url" :content="`${host}/assets/page-preview.webp`">
      <meta property="og:image:secure_url" :content="`${host}/assets/page-preview.webp`">
      <meta property="og:image:alt" content="Hero Image">
      <meta property="og:image:type" content="image/png">
      <meta property="og:image:width" content="4800">
      <meta property="og:image:height" content="2512">
      <meta property="og:locale" :content="locales[currentLanguage].locale">
      <meta v-for="[code, lang] in Object.entries(locales)" property="og:locale:alternate" :content="lang.locale">
      <meta property="og:site_name" content="Clasora">
      <meta name="twitter:card" :content="`${host}/assets/page-preview.webp`">
      <meta name="twitter:url" :content="`${host}${url}`">
      <meta name="twitter:title" :content="title">
      <meta name="twitter:description" :content="description">
      <meta name="twitter:image" :content="`${host}/assets/page-preview.webp`">
      <link v-for="[code, lang] in Object.entries(locales)" rel="alternate" :hreflang="code" :href="route([...route().current().split('.').slice(0, -1), code].join('.'), route().params)">
    </Head>
    <div class="pt-6 md:pt-24 pb-24 bg-gray-200 overflow-hidden">
      <div class="xl:w-[1216px] xl:min-h-[600px] xl:m-auto xl:px-0 px-6 flex">
        <div class="w-full md:w-1/2 md:pt-12 md:pb-24 sm:pb-12 sm:pt-6 sm:pb-3 pt-2 pb-0">
          <h1 class="font-bold text-5xl text-primary"><span class="relative inline-block">{{ title ? title : $t('meta.title')  }} <span alt="Arrow Icon" class="absolute left-0 bottom-[-10px] w-full h-[12px]"><StrokeSVG /></span></span></h1>
          <p class="text-gray-500 pt-4 mt-4">
            {{ $t('meta.description') }}
          </p>
          <p class="text-gray-500 pt-4 mt-4">
            {{ $t('welcome.description_1') }}
          </p>
          <div class="my-8">
            <button
              class="inline-flex items-center justify-center min-w-[186px] px-6 py-3 text-white whitespace-nowrap bg-primary border border-primary rounded-md hover:bg-primary-dark"
              @click="scrollToResults">{{ $t('Explore Classes') }} <span alt="Arrow Icon" class="w-4 h-4 ml-2"><ArrowRightSVG /></span>
            </button>
          </div>
          <div class="mt-1">
            <p class="flex flex-wrap gap-1">
              <span class="text-gray-500 pt-1 px-2 whitespace-nowrap mr-1 self-center">{{ $t('Popular') }}:</span>
              <Link v-for="subject in popularSubjects" :key="subject" @click.prevent="setSelectedSubject($t(`subjects.${subject}`).toLowerCase())" :href="`/${url.split('/')[1]}/${url.split('/')[2]}/${$t(`subjects.${subject}`).toLowerCase()}`" class="bg-white text-gray-500 px-4 py-2 rounded whitespace-nowrap mr-1.5 cursor-pointer">
                {{ $t(`subjects.${subject}`) }}
              </Link>
            </p>
          </div>
        </div>
        <div class="hidden md:block w-1/2 h-100 relative">
          <span alt="Arrow Icon" class="object-cover object-top absolute inset-0 max-h-full w-full z-0">
            <img src="/assets/hero-image.webp" alt="Clasora Welcome Image" title="Clasora Welcome Image" class="object-cover object-top w-full h-full" loading="lazy"/>
          </span>
        </div>
      </div>
    </div>

    <div class="w-full -mt-10" ref="postsRef">
      <div class="pt-2 pb-6" id="search">
        <form 
          @submit.prevent="searchPosts(1)"
          class="flex flex-wrap gap-4 p-4 bg-white rounded-none lg:rounded-lg xl:w-[1216px] m-auto w-full">
          <select 
            v-model="selectedSubject"
            @change="onSelectSubjectChange(1)"
            class="z-[1] w-full md:w-half-minus-8 lg:w-1/3-minus-16 flex items-center justify-center bg-gray-100 border border-gray-100 rounded text-gray-500  focus:outline-none focus:ring-0 focus:border-transparent cursor-pointer"
          >
            <option value='' class="text-gray-500 p-2">{{ $t('I want to learn') }}</option>
            <option v-for="subject in subjects" :key="subject.id" :value="$t(`subjects.${subject.name}`)" class="text-gray-500 p-2" :selected="selectedSubject === $t(`subjects.${subject.name}`)">
              {{ $t(`subjects.${subject.name}`) }}
            </option>
          </select>
          <select v-model="selectedTags"
            @change="searchPosts(1)"
            class="z-[1] w-full md:w-half-minus-8 lg:w-1/3-minus-16 flex items-center justify-center bg-gray-100 border border-gray-100 rounded text-gray-500 focus:outline-none focus:ring-0 focus:border-transparent cursor-pointer">
            <option value='' class="text-gray-500 p-2">{{ $t('Select tag') }}</option>
            <option v-for="tag in tags" :key="tag.id" :value="$t(`subjects.${tag.name}`)" class="text-gray-500 p-2" :selected="selectedTags === $t(`subjects.${tag.name}`)">
              {{ $t(`subjects.${tag.name}`) }}
            </option>
          </select>
          <select v-model="selectedLanguage"
            @change="searchPosts(1)"
            class="z-[1] w-full md:w-half-minus-8 lg:w-1/3-minus-16 flex items-center justify-center bg-gray-100 border border-gray-100 rounded text-gray-500 focus:outline-none focus:ring-0 focus:border-transparent cursor-pointer">
            <option value='any' class="text-gray-500 p-2">{{ $t('Teaches in any language')}}</option>
            <option v-for="language in languages" :key="language.id" :value="$t(language.code)" class="text-gray-500 p-2" :selected="selectedLanguage === $t(language.code)">
              {{ $t('Teaches in') }}: {{ $t(`languages.${language.name}`) }}
            </option>
          </select>
          <select v-model="selectedPriceSort"
            @change="searchPosts(1)"
            class="z-[1] w-full md:w-half-minus-8 lg:w-1/3-minus-16 flex items-center justify-center bg-gray-100 border border-gray-100 rounded text-gray-500 focus:outline-none focus:ring-0 focus:border-transparent cursor-pointer">
            <option value='' class="text-gray-500 p-2">{{ $t('Sort') }}</option>
            <option value="price_asc" class="text-gray-500 p-2" :selected="selectedPriceSort === 'price_asc'">{{ $t('Price is increasing') }}</option>
            <option value="price_desc" class="text-gray-500 p-2" :selected="selectedPriceSort === 'price_desc'">{{ $t('Price is decreasing') }}</option>
          </select>
          <div class="w-full md:w-half-minus-8 lg:w-1/3-minus-16 relative z-[1]">
            <input v-model="searchCity" type="text" :placeholder="$t('Search by city')"
            class="w-full flex items-center justify-center bg-gray-100 border border-gray-100 rounded text-gray-500 focus:outline-none focus:ring-0 focus:border-transparent" />
            <span v-if="searchCity" @click="removeSearchCity" class="cursor-pointer absolute right-2 top-3 font-bold text-gray-600 select-none">&#x2715;</span>
          </div>
          <div class="hidden md:block md:w-half-minus-8 lg:hidden"></div>
          <div class="w-full md:w-half-minus-8 lg:w-1/3-minus-16 flex items-center justify-center z-[1]">
            <button
              type="submit"
              @click="searchPosts(1)"
              class="w-full text-white bg-primary rounded-md border border-primary hover:bg-primary-dark flex items-center justify-center py-2 px-3"> <span>{{ $t('Search') }}</span> <span alt="Search Icon" class="w-4 h-4 ml-2"><SearchSVG /></span> </button>
          </div>
        </form>
      </div>

      <div class="pb-12" id="results">
        <Posts v-if="posts.data" :selectedPost="selectedPost" :posts="posts.data" />
      </div>
      
      <div v-if="posts.data.length === 0" class="pb-12">
        <div class="flex flex-col items-center justify-center">
          <p class="text-gray-500">{{ $t('There are no results for this search') }}</p>
        </div>
      </div>

      <div class="pb-12">
        <Pagination :posts="posts" @change="searchPosts"/>
      </div>

    </div>
  </PageLayout>
  <Onboarding />
</template>


<style scoped>
.underline-cursive {
  position: relative;
  display: inline-block;
}

.underline-cursive::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  /* Adjust based on text and SVG */
  width: 100%;
  height: 12px;
  /* Adjust based on SVG height */
  background-image: url('../../images/stroke.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
</style>