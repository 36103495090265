<script setup>
import { Head, Link, usePage } from '@inertiajs/vue3';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import PostPreview from '@/Components/PostPreview.vue';
import PostSection from '@/Components/PostSection.vue';
import { trans, getActiveLanguage } from 'laravel-vue-i18n';
import { onMounted, ref } from 'vue'
const page = usePage()

const currentLanguage = ref(getActiveLanguage());

const props = defineProps({
  posts: {
    type: Array,
    required: true
  },
  selectedPost: {
    type: Object,
    required: false
  }
});

const sections = ref([]);
props.posts.forEach((post, _i) => {
  if (post.section && props.posts[_i - 1] && !props.posts[_i - 1].section) {
    // new section
    sections.value.push({
      ...post.section,
      posts: []
    });
  } else if (!props.posts[_i - 1]) {
    // new section
    sections.value.push({
      ...post.section,
      posts: []
    });
  } else if (!post.section && props.posts[_i - 1] && props.posts[_i - 1].section) {
    // new section
    sections.value.push({
      ...post.section,
      posts: []
    });
  }
  sections.value[sections.value.length - 1].posts.push(post);
});

const generateJsonLd = (route) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": props.posts.map(post => {
      let courseMode = 'Blended';
      if (post.online && !post.in_person) {
        courseMode = 'Online';
      }
      if (post.in_person && !post.online) {
        courseMode = 'Onsite';
      }
      let item = {
        "@type": "Course",
        "name": {
          "@value": post.title,
          "@language": currentLanguage.value
        },
        "description": {
          "@value": post.description,
          "@language": currentLanguage.value
        },
        "provider": {
          "@type": "Person",
          "name": `${post.user.first_name} ${post.user.last_name}`,
          "image": post.user_image
        },
        "hasCourseInstance": {
          "@type": "CourseInstance",
          "courseMode": courseMode,
          "instructor": {
              "@type": "Person",
              "name": `${post.user.first_name} ${post.user.last_name}`
          },
          "courseWorkload": "P2D"
        },
        "offers": {
          "@type": "Offer",
          "price": post.price,
          "priceCurrency": post.currency,
          "availability": "https://schema.org/InStock",
          "category": "Tutoring"
        },
        "educationalAlignment": {
          "@type": "AlignmentObject",
          "alignmentType": "EducationalSubject",
          "targetName": post.subject.name
        },
        "duration": `PT${post.duration}M`,
        "inLanguage": post.languages[0] ? {
          "@type": "Language",
          "name": post.languages[0].name
        } : {},
        "availableLanguage": post.languages.length > 1 ? post.languages.slice(1).map(lang => {
          return {
            "@type": "Language",
            "name": lang.name
          };
        }) : [],
        "url": route(`post.show.${currentLanguage.value}`, { post: post.post_slug })
      };
      if (post.in_person) {
      item["location"] = {
            "@type": "Place",
            "name": `${post.city}, ${post.country}`
        };
    }
      if (post.rating_count > 0) {
        item["aggregateRating"] = {
          "@type": "AggregateRating",
          "ratingValue": post.rating,
          "reviewCount": post.rating_count
        };
      }
      return item;
    })
  };

  // Return formatted JSON-LD string
  return `<script type="application/ld+json">${JSON.stringify(jsonLd, null, 2)}<\/script>`;
};

</script>

<template>
  <div v-html="generateJsonLd(route)"></div>
  <div class="flex flex-col gap-6 xl:w-[1216px] m-auto">
    <template v-for="(section, _i) in sections">
      <template v-if="!section.description">
        <template v-for="(post, _i) in section.posts" :key="post.id">
          <PostPreview :selected="selectedPost?.id === post.id" :post="post" />
        </template> 
      </template>
      <PostSection v-else :title="section.title" :description="section.description">
        <template v-for="(post, _i) in section.posts" :key="post.id">
          <PostPreview :selected="selectedPost?.id === post.id" :post="post" />
        </template> 
      </PostSection>
    </template>
  </div>
</template>